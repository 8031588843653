import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getDataUser = createAsyncThunk("pendaftaran/getDataUser", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pendaftar/get-user`);
    return response.data;
});
export const getDataUserList = createAsyncThunk("pendaftaran/getDataUserList", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pendaftar/get-user-list`);
    return response.data;
});

export const getDataUserListAll = createAsyncThunk("pendaftaran/getDataUserList", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pendaftar/get-user-list-all`);
    return response.data;
});

export const getUser = createAsyncThunk("pendaftaran/getUser", async(userId) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/users/${userId}`);
    return response.data;
});
export const getProdi = createAsyncThunk("pendaftaran/getProdi", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/program-studi`);
    return response.data;
});

export const getListAgama = createAsyncThunk("pendaftaran/getListAgama", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/agama`);
    return response.data;
});

export const getJenisKelamin = createAsyncThunk("pendaftaran/getJenisKelamin", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/jenis-kelamin`);
    return response.data;
});

export const getJenisPendaftaran = createAsyncThunk("pendaftaran/getJenisPendaftaran", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/jenis-pendaftaran`);
    return response.data;
});

export const getUserNotReguler = createAsyncThunk("pendaftaran/getUserNotReguler", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/mapping/daftar`);
    return response.data;
});

export const getNegara = createAsyncThunk("pendaftaran/getNegara", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`/v1/lookup/wilayah/negara/all`);
    return response.data;
});
export const getProvinsi = createAsyncThunk("pendaftaran/getProvinsi", async(kode) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/wilayah/provinsi/${kode}`);
    return response.data;
});
export const getKota = createAsyncThunk("pendaftaran/getKota", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/wilayah/kota/${id}`);
    return response.data;
});
export const getKecamatan = createAsyncThunk("pendaftaran/getKecamatan", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/wilayah/kota/${id}`);
    return response.data;
});
export const getKelurahan = createAsyncThunk("pendaftaran/getKelurahan", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/wilayah/kota/${id}`);
    return response.data;
});
export const getPekerjaan = createAsyncThunk("pendaftaran/getPekerjaan", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/pekerjaan`);
    return response.data;
});
export const getPendidikan = createAsyncThunk("pendaftaran/getPendidikan", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/jenjang-pendidikan`);
    return response.data;
});
export const getPenghasilan = createAsyncThunk("pendaftaran/getPenghasilan", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/penghasilan`);
    return response.data;
});

export const updateRegistrasiUlang = createAsyncThunk("pendaftaran/updateRegistrasiUlang", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/registasi-ulang`,val);
    return response.data;
});

export const updateRegistrasiUlangAdmin = createAsyncThunk("pendaftaran/updateRegistrasiUlangAdmin", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/registasi-ulang/admin`,val);
    return response.data;
});

export const updateRegistrasiUlangPartial = createAsyncThunk("pendaftaran/updateRegistrasiUlangPartial", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/registasi-ulang/partial`,val);
    return response.data;
});
export const updateRegistrasiUlangAll = createAsyncThunk("pendaftaran/updateRegistrasiUlangAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/registasi-ulang/all`,val);
    return response.data;
});

export const updateDataPendaftarProdi = createAsyncThunk("pendaftaran/updateDataPendaftarProdi", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/update_prodi_pendaftar`,val);
    return response.data;
});

export const getAllPT = createAsyncThunk("pendaftaran/getAllPT", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/lookup/all-pt`);
    return response.data;
});

const initialState = {
    loading: false,
    loadingDataUser : false,
    loadingUser : false,
    loadingProdi : false,
    loadingAgama : false,
    loadingUserNotReguler : false,
    loadingAllPT : false,
    responseDataUser : {},
    responseDataUserList : [],
    responseUser:{},
    dataProdi:[],
    dataAgama:[],
    dataJenisKelamin:[],
    dataJenisPendaftaran:[],
    dataUserNotReguler:[],
    listNegara:[],
    listProvinsi:[],
    listKota:[],
    listKecamatan:[],
    listKelurahan:[],
    listPendidikan:[],
    listPekerjaan:[],
    listPenghasilan:[],
    listAllPT:[],
    loadingUpdateRegUlang:false,
    responseUpdateRegUlang:{}
}

const dataPendaftaranSlice = createSlice({
    name:"dataPendaftaranSlice",
    initialState: initialState,
    extraReducers: {
        [updateRegistrasiUlang.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },
        [updateRegistrasiUlang.fulfilled] : (state, action) => {
            state.responseUpdateRegUlang = action.payload;
            state.loadingUpdateRegUlang = false;
        },
        [updateRegistrasiUlang.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },

        [updateRegistrasiUlangAdmin.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },
        [updateRegistrasiUlangAdmin.fulfilled] : (state, action) => {
            state.responseUpdateRegUlang = action.payload;
            state.loadingUpdateRegUlang = false;
        },
        [updateRegistrasiUlangAdmin.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },

        [updateRegistrasiUlangPartial.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },
        [updateRegistrasiUlangPartial.fulfilled] : (state, action) => {
            state.responseUpdateRegUlang = action.payload;
            state.loadingUpdateRegUlang = false;
        },
        [updateRegistrasiUlangPartial.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },

        [updateRegistrasiUlangAll.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },
        [updateRegistrasiUlangAll.fulfilled] : (state, action) => {
            state.responseUpdateRegUlang = action.payload;
            state.loadingUpdateRegUlang = false;
        },
        [updateRegistrasiUlangAll.pending] : (state, action) => {
            state.loadingUpdateRegUlang = true;
        },

        [updateDataPendaftarProdi.pending] : (state, action) => {
            state.loading = true;
        },
        [updateDataPendaftarProdi.fulfilled] : (state, action) => {
            state.responseDataUser = action.payload;
            state.loading = false;
        },
        [updateDataPendaftarProdi.pending] : (state, action) => {
            state.loading = true;
        },

        [getDataUser.pending] : (state, action) => {
            state.loadingDataUser = true;
        },
        [getDataUser.fulfilled] : (state, action) => {
            state.responseDataUser = action.payload;
            state.loadingDataUser = false;
        },
        [getDataUser.pending] : (state, action) => {
            state.loadingDataUser = true;
        },

        [getDataUserList.pending] : (state, action) => {
            state.loadingDataUser = true;
        },
        [getDataUserList.fulfilled] : (state, action) => {
            state.responseDataUserList = action.payload;
            state.loadingDataUser = false;
        },
        [getDataUserList.pending] : (state, action) => {
            state.loadingDataUser = true;
        },

        [getDataUserListAll.pending] : (state, action) => {
            state.loadingDataUser = true;
        },
        [getDataUserListAll.fulfilled] : (state, action) => {
            state.responseDataUserList = action.payload;
            state.loadingDataUser = false;
        },
        [getDataUserListAll.rejected] : (state, action) => {
            state.loadingDataUser = false;
        },

        [getUser.pending] : (state, action) => {
            state.loadingUser = true;
        },
        [getUser.fulfilled] : (state, action) => {
            state.responseUser = action.payload;
            state.loadingUser = false;
        },
        [getUser.pending] : (state, action) => {
            state.loadingUser = true;
        },
        [getProdi.pending] : (state, action) => {
            state.loadingProdi = true;
        },
        [getProdi.fulfilled] : (state, action) => {
            state.dataProdi = action.payload;
            state.loadingProdi = false;
        },
        [getProdi.pending] : (state, action) => {
            state.loadingProdi = true;
        },
        [getListAgama.pending] : (state, action) => {
            state.loadingAgama = true;
        },
        [getListAgama.fulfilled] : (state, action) => {
            state.dataAgama = action.payload;
            state.loadingAgama = false;
        },
        [getListAgama.rejected] : (state, action) => {
            state.loadingAgama = true;
        },
        [getJenisKelamin.pending] : (state, action) => {
            state.loading = true;
        },
        [getJenisKelamin.fulfilled] : (state, action) => {
            state.dataJenisKelamin = action.payload;
            state.loading = false;
        },
        [getJenisKelamin.rejected] : (state, action) => {
            state.loading = true;
        },
        [getJenisPendaftaran.pending] : (state, action) => {
            state.loading = true;
        },
        [getJenisPendaftaran.fulfilled] : (state, action) => {
            state.dataJenisPendaftaran = action.payload;
            state.loading = false;
        },
        [getJenisPendaftaran.rejected] : (state, action) => {
            state.loading = true;
        },
        [getUserNotReguler.pending] : (state, action) => {
            state.loadingUserNotReguler = true;
        },
        [getUserNotReguler.fulfilled] : (state, action) => {
            state.dataUserNotReguler = action.payload;
            state.loadingUserNotReguler = false;
        },
        [getUserNotReguler.pending] : (state, action) => {
            state.loadingUserNotReguler = true;
        },
        [getNegara.pending] : (state, action) => {
            state.loading = true;
        },
        [getNegara.fulfilled] : (state, action) => {
            state.listNegara = action.payload;
            state.loading = false;
        },
        [getNegara.rejected] : (state, action) => {
            state.loading = false;
        },
        [getProvinsi.pending] : (state, action) => {
            state.loading = true;
        },
        [getProvinsi.fulfilled] : (state, action) => {
            state.listProvinsi = action.payload;
            state.loading = false;
        },
        [getProvinsi.rejected] : (state, action) => {
            state.loading = false;
        },
        [getKota.pending] : (state, action) => {
            state.loading = true;
        },
        [getKota.fulfilled] : (state, action) => {
            state.listKota = action.payload;
            state.loading = false;
        },
        [getKota.rejected] : (state, action) => {
            state.loading = false;
        },
        [getKecamatan.pending] : (state, action) => {
            state.loading = true;
        },
        [getKecamatan.fulfilled] : (state, action) => {
            state.listKecamatan = action.payload;
            state.loading = false;
        },
        [getKecamatan.rejected] : (state, action) => {
            state.loading = false;
        },
        [getKelurahan.pending] : (state, action) => {
            state.loading = true;
        },
        [getKelurahan.fulfilled] : (state, action) => {
            state.listKelurahan = action.payload;
            state.loading = false;
        },
        [getKelurahan.rejected] : (state, action) => {
            state.loading = false;
        },
        [getPekerjaan.pending] : (state, action) => {
            state.loading = true;
        },
        [getPekerjaan.fulfilled] : (state, action) => {
            state.listPekerjaan = action.payload;
            state.loading = false;
        },
        [getPekerjaan.rejected] : (state, action) => {
            state.loading = false;
        },
        [getPendidikan.pending] : (state, action) => {
            state.loading = true;
        },
        [getPendidikan.fulfilled] : (state, action) => {
            state.listPendidikan = action.payload;
            state.loading = false;
        },
        [getPendidikan.rejected] : (state, action) => {
            state.loading = false;
        },
        [getPenghasilan.pending] : (state, action) => {
            state.loading = true;
        },
        [getPenghasilan.fulfilled] : (state, action) => {
            state.listPenghasilan = action.payload;
            state.loading = false;
        },
        [getPenghasilan.rejected] : (state, action) => {
            state.loading = false;
        },
        [getAllPT.pending] : (state, action) => {
            state.loadingAllPT = true;
        },
        [getAllPT.fulfilled] : (state, action) => {
            state.listAllPT = action.payload;
            state.loadingAllPT = false;
        },
        [getAllPT.rejected] : (state, action) => {
            state.loadingAllPT = false;
        },
    }
})

export const dataPendaftaranSelector = (state) => state.dataPendaftaran;
export default dataPendaftaranSlice.reducer;