import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import jwtAuthAxios, {setTokenManual} from "../../services/jwtAuth";
import registerAxios from "../../services/jwtAuth";
import jwtAuth from "../../services/jwtAuth";
import axios from "axios";
export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}
export const signIn =
	createAsyncThunk('auth/signIn',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		let loginCreds = {email, password};
		const response = await jwtAuthAxios.post('/v1/auth/authenticate', loginCreds);
		if (response.data) {
			//console.log("response.data : ",JSON.stringify(response.data))
			if (response.data.result) {
				const token = response.data.token.access_token;
				const roles = response.data.roles;
				
				localStorage.setItem(AUTH_TOKEN, token);
				localStorage.setItem('roles', roles);
				localStorage.setItem('user_id', response.data.user_id);
				localStorage.setItem('camaba_id', response.data.camaba_id);
				localStorage.setItem('registration_id', response.data.registration_id);
				localStorage.setItem('prodi_id', response.data.prodi_id);
				
				setTokenManual(token);
				return token;
			} else {
				return rejectWithValue('Not Authorize');
			}
		} else {
			//return rejectWithValue(response.message?.replace('Firebase: ', ''));
			return rejectWithValue('Not Authorize');
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})


export const signUp =
	createAsyncThunk('auth/signUp',async (data, { rejectWithValue }) => {
	const { firstName,lastName,email, password,role,mobilePhone } = data
	try {
		let loginCreds = {firstName,lastName,email, password,role,mobilePhone};
		const response = await axios.post('/pmb/auth/register', loginCreds);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/signOut',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem("roles");
    return response.data
})

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/auth/login'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				//state.redirect = '/'
				//state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer