import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const adminRoutes = [
// MataKuliah Penyetaraan
    {
        key: 'matakuliah',
        path: `${APP_PREFIX_PATH}/matakuliah`,
        component: React.lazy(() => import('views/admin-views/mata-kuliah/html')),
    },
    {
        key: 'matakuliah.penyetaraan',
        path: `${APP_PREFIX_PATH}/matakuliah/penyetaraan`,
        component: React.lazy(() => import('views/admin-views/mata-kuliah/penyetaraan-makul/View')),
    },
    {
        key: 'matakuliah.penyetaraan.detail',
        path: `${APP_PREFIX_PATH}/matakuliah/penyetaraan/detail/:id`,
        component: React.lazy(() => import('views/admin-views/mata-kuliah/penyetaraan-makul/detail/DetailMataKuliah')),
    },
// PAYMENT
    {
        key: 'payment.list',
        path: `${APP_PREFIX_PATH}/payment/list`,
        component: React.lazy(() => import('views/admin-views/payment/list')),
    },
    {
        key: 'payment.list.detail',
        path: `${APP_PREFIX_PATH}/payment/list/detail/:id`,
        component: React.lazy(() => import('views/admin-views/payment/list/detail')),
    },
    {
        key: 'payment.list.invoice',
        path: `${APP_PREFIX_PATH}/payment/list/invoice/:id`,
        component: React.lazy(() => import('views/admin-views/payment/list/invoice')),
    },
    {
        key: 'payment.add',
        path: `${APP_PREFIX_PATH}/payment/add`,
        component: React.lazy(() => import('views/admin-views/payment/add')),
    },
    {
        key: 'payment.add.view',
        path: `${APP_PREFIX_PATH}/payment/add/view/:id`,
        component: React.lazy(() => import('views/admin-views/payment/add/view/ViewAdminPayment')),
    },
    {
        key: 'payment.add.view.detail',
        path: `${APP_PREFIX_PATH}/payment/add/view/detail/:id`,
        component: React.lazy(() => import('views/admin-views/payment/add/view/detail')),
    },
    {
        key: 'payment.edit-list',
        path: `${APP_PREFIX_PATH}/payment/edit-list`,
        component: React.lazy(() => import('views/admin-views/payment/edit-list')),
    },
    {
        key: 'payment.edit-list.update',
        path: `${APP_PREFIX_PATH}/payment/edit-list/update/:id`,
        component: React.lazy(() => import('views/admin-views/payment/edit-list/update')),
    },

    // Camaba
    {
        key: 'camaba',
        path: `${APP_PREFIX_PATH}/camaba`,
        component: React.lazy(() => import('views/admin-views/camaba')),
    },
    {
        key: 'camaba-laporan-registrasi',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar')),
    },
    {
        key: 'camaba-laporan-registrasi',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar/edit`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar/edit')),
    },
    {
        key: 'camaba-laporan-registrasi',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar/edit-partial`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar/edit-partial')),
    },
    {
        key: 'camaba-laporan-registrasi',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar/view`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar/view')),
    },
    {
        key: 'camaba-laporan-registrasi-view-update',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar/view-update`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar/view-update')),
    },
    {
        key: 'camaba-laporan-registrasi-update-prodi',
        path: `${APP_PREFIX_PATH}/camaba/data-pendaftar/update-prodi`,
        component: React.lazy(() => import('views/admin-views/camaba/data-pendaftar/update-prodi')),
    },
]